export default {
  AllDay: 'All Day',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  WEEK_DAYS: {
    MONDAY: {
      key: 'Monday',
      value: 'MONDAY',
      order: 1,
    },
    TUESDAY: {
      key: 'Tuesday',
      value: 'TUESDAY',
      order: 2,
    },
    WEDNESDAY: {
      key: 'Wednesday',
      value: 'WEDNESDAY',
      order: 3,
    },
    THURSDAY: {
      key: 'Thursday',
      value: 'THURSDAY',
      order: 4,
    },
    FRIDAY: {
      key: 'Friday',
      value: 'FRIDAY',
      order: 5,
    },
    SATURDAY: {
      key: 'Saturday',
      value: 'SATURDAY',
      order: 6,
    },
    SUNDAY: {
      key: 'Sunday',
      value: 'SUNDAY',
      order: 0,
    },
  },
  CALL_STATUS: {
    STARTED: 'In Progress',
    SCHEDULED: 'Scheduled',
    COMPLETED: 'Completed',
    ENDED: 'Ended',
  },
  PARTICIPANT_STATUS: {
    ADDED: 'ADDED',
    JOINED: 'JOINED',
    LEFT: 'LEFT',
    NOT_ATTENDED: 'NOT_ATTENDED',
    REMOVED: 'REMOVED',
  },
  APP_PRIVILEGES: {
    VIEW_DIARY: 'VIEW_DIARY',
    SUBMIT_FORM: 'SUBMIT_FORM',
    VIEW_CALL: 'VIEW_CALL',
    VIEW_COMPLIANCE: 'VIEW_COMPLIANCE',
    VIEW_CHAT: 'VIEW_CHAT',
    CREATE_CHAT: 'CREATE_CHAT',
    VIEW_HEALTH_DATA: 'VIEW_HEALTH_DATA',
    VIEW_NOTIFICATIONS: 'VIEW_NOTIFICATIONS',
    VIEW_TIMEZONE: 'VIEW_TIMEZONE',
    VIEW_CONTACT: 'VIEW_CONTACT',
    SETUP_APPLOCK: 'SETUP_APPLOCK',
    VIEW_FAQ: 'VIEW_FAQ',
  },
  SUBJECT_PRIVILEGES: [
    'VIEW_DIARY',
    // 'SUBMIT_FORM',
    'VIEW_CALL',
    'VIEW_COMPLIANCE',
    'VIEW_CHAT',
    // 'CREATE_CHAT',
    'VIEW_HEALTH_DATA',
    'VIEW_NOTIFICATIONS',
    'VIEW_TIMEZONE',
    'VIEW_CONTACT',
    'SETUP_APPLOCK',
    'VIEW_FAQ',
  ],
  FormTypes: {
    NORMAL: 'NORMAL',
    DEVICE_DATA: 'DEVICE_DATA',
    SUBJECT_DIARY : 'SUBJECT_DIARY',
    RATER_ASSESSMENT: 'RATER_ASSESSMENT'
  },
  AppType: {
    SITESTAFF: 'clinician',
    SUBJECT: 'participant',
    PREVIEW: 'preview',
    PRINT: 'print'
  },
  OfflineDataStatus: {
    ACTIVE: 'ACTIVE',
    SYNC_FAILED: 'SYNC_FAILED',
    INVALID_TOKEN: 'INVALID_TOKEN',
    IN_PROGRESS: 'IN_PROGRESS',
    SUCCESS: 'SUCCESS',
    NOT_STARTED: 'NOT_STARTED',
    PARTIALLY_COMPLETED: 'PARTIALLY_COMPLETED',
  },
  formCategory: {
    RATER_ASSESSMENT: 'Rater/Assessment Forms',
    SELF_ASSESSMENT: 'Self Assessment Forms',
    SUBJECT_DIARY: 'SUBJECT_DIARY',
  },
  subjectStatus: {
    NOT_STARTED: 'Not Started',
    ACTIVE: 'Active',
    WITH_DRAWN: 'Withdrawn',
  },
  visitStatus: {
    INPROGRESS: 'In Progress',
    COMPLETED: 'Completed',
    NOT_STARTED: 'Not Started',
    ENABLED: 'Enabled',
    NOT_COMPLETED: 'Not Completed',
    PARTIALLY_COMPLETED: 'Partially Completed',
  },
  ContextProperties: {
    PRIMARY_ORG_CODE: '{{PRIMARY_ORG_CODE}}',
    STUDY_ID: '{{STUDY_ID}}',
    SITE_ID: '{{SITE_ID}}',
    CRF_VERSION_ID: '{{CRF_VERSION_ID}}',
    SUBJECT_ID: '{{SUBJECT_ID}}'
  },
  targetEntityType: {
    GLOBAL: 'GLOBAL',
    ORGANIZATION: 'ORGANIZATION',
    STUDY: 'STUDY',
  },

  errorMessageType: [
    {
      key: 70,
      message: 'ResetPassword',
    },
    {
      key: 71,
      message: 'TermsAndConditions',
    },
  ],

  labelPlacement: {
    TOP: 'TOP',
    BOTTOM: 'BOTTOM',
    LEFT: 'LEFT',
    RIGHT: 'RIGHT'
  },
  SCALE_POSITION : {
    CENTRE_OF_THE_PAGE: 'CENTRE_OF_THE_PAGE',
    LEFT_SIDE_OF_THE_PAGE: 'LEFT_SIDE_OF_THE_PAGE',
    RIGHT_SIDE_OF_THE_PAGE: 'RIGHT_SIDE_OF_THE_PAGE',
  },
  DO_NOT_DISPLAY_CHOICES: 'DO_NOT_DISPLAY_CHOICES',

  TOOLTIP_PLACEMENT : {
    TOP: "top",
    BOTTOM: "bottom",
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right",
  },
  LABEL_FONT_SIZE: 15,
  VERTICAL_LABEL_HEIGHT_LIMIT: 50,
  EDGE_LABEL_HEIGHT: 200,
  
  excludedLocalizationKeys: [
    'showValue',
    'showMarks',
    'isPercentage',
    'orientation',
    'start',
    'end',
    'step',
    'min',
    'max',
    'scaleVerticalAlignment',
    'indicationMultiple',
    'indicationNumbers',
    'showOptions',
    'range',
    'img',
    'imageName',
    'imageCode',
    'highlightColor',
    'id',
    'score',
    'disabledScore',
    'disabled',
  ],
  dateFormats: {
    DATE: 'DD-MMM-YYYY',
    DATETIME: 'DD-MMM-YYYY hh:mm A'
  },
  timeFormats: {
    timeFormat12: 'hh:mm A',
    timeFormat24: 'HH:mm',
  },
  datepickerMode: {
    date: 'date',
    datetime: 'datetime',
    time: 'time',
  },
  platForm: {
    android: 'android',
    web: 'web',
    ios: 'ios',
  },
  timeKeys: {
    hour: 'hour',
    minute: 'minute',
    meridian: 'meridian'
  },
  locale:{
    english: 'en-us',
    hindi: 'hi'
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { View, Text, StyleSheet } from 'react-native'
import moment from 'moment'
import TimeAdjuster from './TimeAdjuster'
import constants from '../../constants/constants'

const { dateFormats, timeFormats, timeKeys } = constants

function CustomTimeView({ selectedDateTime, onTimeChange, t, timeFormat, valueFormat }) {
  const is12Hour = (timeFormat.includes('A') || timeFormat.includes('a'))

  const parsedTime = selectedDateTime ? moment(selectedDateTime, valueFormat) : null
  const hourFormat = is12Hour ? 'hh' : 'HH'
  const selectedHour = parsedTime ? parsedTime.format(hourFormat) : ''
  const selectedMinute = parsedTime ? parsedTime.format('mm') : ''
  const selectedMeridian = is12Hour && parsedTime ? parsedTime.format('A') : ''
  let meridian = selectedMeridian


  const getUpdatedHour = (selectedHour, increment, is12Hour) => {
    let updatedHour = Number(selectedHour)
    updatedHour = is12Hour
      ? (updatedHour + increment - 1 + 12) % 12 + 1
      : (updatedHour + increment + 24) % 24
    return updatedHour < 10 ? `0${updatedHour}` : `${updatedHour}`
  }

  const getUpdatedMinute = (selectedMinute, increment) => {
    let updatedMinute = (Number(selectedMinute) + increment + 60) % 60
    return updatedMinute < 10 ? `0${updatedMinute}` : `${updatedMinute}`
  }

  const toggleMeridian = (selectedMeridian) => (selectedMeridian === "AM" ? "PM" : "AM")

  const handleTimeChange = (type, increment) => {
    let updatedTime = `${selectedHour}:${selectedMinute}`
  
    if (is12Hour && type === "meridian") {
      meridian = toggleMeridian(selectedMeridian)
      updatedTime += ` ${meridian}`
    }

    switch (type) {
      case "hour": {
        const updatedHour = getUpdatedHour(selectedHour, increment, is12Hour)
        updatedTime = is12Hour
          ? `${updatedHour}:${selectedMinute} ${meridian}`
          : `${updatedHour}:${selectedMinute}`
        break
      }
      case "minute": {
        const updatedMinute = getUpdatedMinute(selectedMinute, increment)
        updatedTime = is12Hour
          ? `${selectedHour}:${updatedMinute} ${meridian}`
          : `${selectedHour}:${updatedMinute}`
        break
      }
      case "meridian": {
        updatedTime = `${selectedHour}:${selectedMinute} ${meridian}`
        break
      }
      default:
        break
  }
  onTimeChange(updatedTime)
}

  return (
    <View style={styles.container}>
      <Text style={styles.timeText}>
        {parsedTime ? parsedTime.format(dateFormats.DATE)+ ' '+`${selectedHour}:${selectedMinute} ${t(meridian)}` : ''}
      </Text>
      <View style={styles.row}>
        <TimeAdjuster
          label={selectedHour}
          onIncrement={() => handleTimeChange(timeKeys.hour, 1)}
          onDecrement={() => handleTimeChange(timeKeys.hour, -1)}
        />
        <Text style={styles.separator}>:</Text>
        <TimeAdjuster
          label={selectedMinute}
          onIncrement={() => handleTimeChange(timeKeys.minute, 1)}
          onDecrement={() => handleTimeChange(timeKeys.minute, -1)}
        />
        {is12Hour && (
          <TimeAdjuster
            label={t(meridian)}
            onIncrement={() => handleTimeChange(timeKeys.meridian, 1)}
            onDecrement={() => handleTimeChange(timeKeys.meridian, -1)}
          />
        )}
      </View>
    </View>
  )
}

CustomTimeView.defaultProps = {
  selectedDateTime: null,
  t: () => {},
  timeFormat: timeFormats.timeFormat12,
  valueFormat: dateFormats.DATETIME,
}

CustomTimeView.propTypes = {
  selectedDateTime: PropTypes.string,
  onTimeChange: PropTypes.func.isRequired,
  t: PropTypes.func,
  timeFormat: PropTypes.string,
  valueFormat: PropTypes.string,
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
  },
  timeText: {
    fontSize: 16,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    fontSize: 16,
  },
})

export default CustomTimeView

import { getSubject, getUser } from "../../selectors/commonSelector";
import { getUserStudyMetaData, getUserStudySite } from "../../selectors/studyMetaData";
import { store } from "../../store/configStore";
import _ from 'lodash'
import constants from '../../constants/constants'

export const getContextBasedUrl = (url) => {
    const state = store.getState();
    const appType = getAppType();
    let contextBasedUrl = url;
    const currentUser = getUser(state)
    const userStudyMetadata = getUserStudyMetaData(state)
    const subject = getSubject(state)
    contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.PRIMARY_ORG_CODE, _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(currentUser,'primaryOrganizationCode', "") : _.get(currentUser?.userData?.data,'primaryOrganizationCode', ""))
    contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.STUDY_ID,  _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(currentUser,'studyId', "") : _.get(userStudyMetadata?.study?.data,'id', ""))
    contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.SITE_ID,   _.isEqual(appType, constants.AppType.SUBJECT) ? _.get(currentUser,'siteId', "") : getUserStudySite(state)?.id)
    contextBasedUrl = _.replace(contextBasedUrl, constants.ContextProperties.SUBJECT_ID, _.get(subject,'id', ""))
    return contextBasedUrl;
  }

  export const getAppType = () => {
    const state = store.getState();
    return state?.appStatus?.appType
  }

import { Platform } from 'react-native'
import constants from '../constants/constants'
import api from '../utils/api'
import { deviceConfig } from '../utils/deviceConfig'
import { setupInitialHealthKit } from '../utils/healthKit/Healthkit'
import {
  setSubjectCredentials, setSubjectAuthToken
} from '../utils/secureStorageUtils'
import { storage } from '../utils/storage'
import showToast from '../utils/toast'
import { store } from '../store/configStore'
import jwt_decode from 'jwt-decode'
import { updatedLoggedinUser, updateUserPrivileges } from './users'
import { generateBasicAuthToken } from '../utils/util'
import _ from 'lodash'
import NavigationService from '../containers/navigationService'
import appConstants from '../constants/appConstants'
import ConstraintStatus from '../utils/constraintKeys'
import moment from 'moment-timezone'
import { clearStorageAndStoreSyncQueue, clearUserStorage } from './syncQueue'
import { isDataExistsToSync } from '../selectors/syncQueue'
import { retrieveLoginFailure, retrieveLoginRequest, updateAppType, updateOnMobileStatus } from './storeAppStatus'
import Constants from 'expo-constants'
import RenderHTML from 'react-native-render-html'
import openapi from '../utils/openapi'
import { SESSION_TIMEOUT, updateCurrentUserId } from './session'
import { updateSubject } from './subject'
import { getUserId } from '../selectors/user'
import { getUser } from '../selectors/commonSelector'
import { updateUserStudySite } from './studyMetaData'
import { decryptString } from '../utils/cryptoUtil'
import * as Linking from 'expo-linking'
import locale_codes from '../utils/localization/localeConstants'

export const sessionTimeout = (timeout, userId) => ({
  type: SESSION_TIMEOUT,
  timeout,
  userId
})


export const retrieveLogin =
  (
    subject,
    deviceToken,
    hasPinSetup,
    t,
    setLocale,
    accessCode
  ) =>
  async (dispatch,getState) => {
    const obj = deviceConfig()
    const data = {
      subject,
      mobileInfo: {
        systemVersion: obj.systemVersion,
        os: obj.os,
        deviceId: getState()?.appStatus?.deviceToken,
        web: Platform.OS === 'web' ? true : false,
      },
    }
    dispatch(retrieveLoginRequest())
    try {
      const config = {
        headers: {
          Authorization: generateBasicAuthToken(subject.phoneNo, subject.password),
        },
      }

      api.defaults.headers.common['Client-Type'] = 'mobile'
      let res = await openapi.post(`/subject/login`, data, config)
      if (res.data?.id) {
        checkUserAndClearStorage(res.data?.id)
        dispatch(updateAppType(constants.AppType.SUBJECT))
        dispatch(updateCurrentUserId(res.data?.id))
      }
      dispatch(updatedLoggedinUser(res.data))
      setSubjectAuthToken(
        res.headers['subjectauthorization-token'],
        res.headers['subjectrefresh-token'], res.data?.id
      )
      const appType = getState().appStatus?.appType;
      const locale = res.data.role === 'SUBJECT' ? res.data.subject.locale : res.data.locale
      setLocale(locale)
      if (Platform.OS === 'web' && accessCode && res.data?.privacyPolicy?.agreedToTerms
      && !res.data.resetPassword && !res.data.passwordExpired && constants.AppType.SITESTAFF !== appType) {
        return openMeeting(accessCode, locale)
      }
      delete subject.password
      const userPrivileges = res.data?.subjectPrivileges
      dispatch(updateSubject(
      {...res.data?.subject,
        trainingCompleted: res.data?.trainingCompleted
      }, 
      res.data?.id))
      dispatch(updateUserStudySite(res.data?.studySite, res.data?.id))
      dispatch(updateUserPrivileges(userPrivileges, res.data?.id))
      if (
        Platform.OS === 'ios' &&
        res.data?.subjectPrivileges?.includes(constants.APP_PRIVILEGES.VIEW_HEALTH_DATA)
      ) {
        setupInitialHealthKit()
      }
      if (!hasPinSetup) setSubjectCredentials(subject)
      if (res.data.passwordExpired) {
        NavigationService.navigate('ResetPassword', {
          phoneNumber: res.data.phoneNo,
          fromLogin: true,
          passwordExpired: true,
        })
      } else if (
        (res.data.role === 'SUBJECT' || res.data.role === 'CAREGIVER') &&
        res.data?.privacyPolicy &&
        !res.data?.privacyPolicy?.agreedToTerms
      ) {
        let params ={}
        if(accessCode && constants.AppType.SITESTAFF !== appType) {
          params = {accessCode:accessCode}
        }
        NavigationService.navigate('TermsAndConditions', params)
      }else if (
        res.data.resetPassword &&
        (res.data.role === 'SUBJECT' || res.data.role === 'CAREGIVER')
      ) {
        let params = {
          phoneNumber: res.data.phoneNo,
          fromLogin: true
        }
        if(accessCode && constants.AppType.SITESTAFF !== appType) {
          params.accessCode = accessCode
          }
        NavigationService.navigate('ResetPassword', params)
      } else if (res.data.role === 'SUBJECT') {
        moment.tz.setDefault(res.data?.subject?.timeZone)
        NavigationService.navigate('OfflineDataLoader')
      } else if (res.data.role === 'CAREGIVER') {
        NavigationService.navigate('SubjectDataLoaderPage')
      }
    } catch (error) {
      console.log(error)
      if (error.response) {
        switch (error.response.status) {
          case 404:
            showToast(t('NetworkError'), 'danger', 3000)
            break
          case 500: {
            showMessageForInternalServerError(error.response.data, t)
            break
          }
          case 401 || 403:
            Constants.expoConfig.extra.nativeApp
              ? showToast(t('InvPhonePSWD'), 'danger', 3000)
              : showToast(<RenderHTML source={{ html: t('InvPhonePSWDWeb')}} />, 'danger', 3000)
            break
          case 423:
            showToast(t('UserLocked'), 'danger', 3000)
            break
          default:
            showToast(t('NetworkError'), 'danger', 3000)
        }
      } else {
        showToast(t('NetworkError'), 'danger', 3000)
      }
      dispatch(retrieveLoginFailure())
    }
  }
export const validateSubjectToken = async () => {
  const userId = getUserId(store.getState())
  try {
    const subjectDeviceToken = decryptString(storage.getString(userId+'SubjectAuthorizationToken'))
    const subjectRefreshToken = decryptString(storage.getString(userId+'SubjectRefreshToken'))
    if (_.isEmpty(subjectDeviceToken)) {
      return {}
    }
    if (_.isEmpty(subjectRefreshToken)) {
      return getAuthToken()
    }
    var decodedSubjectToken = jwt_decode(subjectDeviceToken)
    var decodedSubjectRefreshToken = jwt_decode(subjectRefreshToken)
    if (Date.now() >= decodedSubjectToken.exp * 1000) {
      if (Date.now() >= decodedSubjectRefreshToken.exp * 1000) {
       return navigateToSessionTimeout()
      } else {
        const tokenDetails = await getTokenFromRefreshToken(subjectRefreshToken)
        if (!_.isEmpty(tokenDetails)) {
          setSubjectAuthToken(tokenDetails.token, tokenDetails.refreshToken)
          return {
            subjectDeviceToken: tokenDetails.token,
          }
        } else {
          return navigateToSessionTimeout()
        }
      }
    }
    return getAuthToken()
  } catch (error) {
    console.log(error)
  }
}

const navigateToSessionTimeout = () => {
  const onMobile = store.getState()?.appStatus?.onMobile
  const userId = getUserId(store.getState())
  if (Platform.OS === 'web') {
    store.dispatch(clearUserStorage())
  }
  store.dispatch(updateOnMobileStatus(onMobile))
  store.dispatch(sessionTimeout(true, userId))
  NavigationService.navigate('InitialScreen', {sessionTimeout: true})
}
const getTokenFromRefreshToken = async (refreshToken) => {
  try {
    const res = await api.post(
      `/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${constants.ContextProperties.SUBJECT_ID}/generateToken`,
      { refreshToken }
    )
    return res.data
  } catch (error) {
    console.log(error)
    return null
  }
}
export const getAuthToken = () => {
  const userId = getUserId(store.getState())
  const subjectDeviceToken = decryptString(storage.getString(userId+'SubjectAuthorizationToken'))
  if (!subjectDeviceToken) {
    return {}
  }
  return {
    subjectDeviceToken: subjectDeviceToken,
  }
}

export const updateSubjectDeviceToken = async (subject, userId) => {
  try {
    await api.put(
      `${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/users/${userId}/deviceTokens`,
      {deviceToken: subject.deviceToken}
    )
  } catch (error) {
    console.log(error)
  }
}

export const openMeeting = (accessCode, locale) => {
  const userId = getUserId(store.getState())
  const token = decryptString(storage.getString(userId+'SubjectAuthorizationToken'))
  const meetingUrl = `${appConstants.jitsiMeetUrl}/${accessCode}?jwt=${encodeURIComponent(token)}&lang=${locale_codes[locale]?.code}`
  if (Platform.OS === 'web') {
    window.open(meetingUrl, '_blank')
  } else {
    Linking.openURL(meetingUrl)
  }
}
const showMessageForInternalServerError = (errorCode, t) => {
  if (errorCode && Number(errorCode) === ConstraintStatus.USER_CAREGIVER_INACTIVE) {
    showToast(t('SubjectCaregiverInactive'), 'danger', 3000)
  }
  if (errorCode && (Number(errorCode) === ConstraintStatus.USER_INACTIVE || Number(errorCode) === ConstraintStatus.USER_WITHDRAWN || Number(errorCode) === ConstraintStatus.USER_SCREENING_FAILED)) {
    showToast(t('UserInactive'), 'danger', 3000)
  } else {
    showToast(t('SomethingWrong'), 'danger', 3000)
  }
}

const checkUserAndClearStorage = (currentUser) => {
  const existingUser = getUser(store.getState())
  const isOfflineDataExists = isDataExistsToSync(store.getState())
  if (existingUser && existingUser !== currentUser && !isOfflineDataExists) {
    store.dispatch(clearStorageAndStoreSyncQueue())
  }
}

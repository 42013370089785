import _ from 'lodash'
import api from '../utils/api'
import { getStudyVisitMetaData, updateUserStudySite } from './studyMetaData'
import moment from 'moment-timezone'
import constants from '../constants/constants'
import { updateIsSubjectRegistered, updateUserPrivileges } from './users'
import { getUserId } from '../selectors/user'
import { updateSubject } from './subject'

export const retrieveSubjectsOfCaregiver = (caregiverId) => async (dispatch, getState) => {
  const userId = getUserId(getState())
  try {
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/caregivers/${userId}/subjects`)
    if (res && res.data) {
      if (!_.isEmpty(res.data) && _.size(res.data, 1)) {
        await dispatch(retrieveSubjectById(res.data[0].id))
        await dispatch(getStudyVisitMetaData())
        return res.data
      }
    } else {
      throw Error('Invalid response.')
    }
  } catch (error) {
    console.log(error)
  }
}

export const retrieveSubjectById = (subjectId) => async (dispatch, getState) => {
  const userId = getUserId(getState())
  try {
    api.defaults.headers.common['subject-id'] = subjectId
    const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/subjects/${subjectId}`)
    const subjectPrivileges = res.data ? res.data?.subjectPrivileges : []
    await dispatch(updateUserPrivileges(subjectPrivileges, userId))
    await dispatch(updateIsSubjectRegistered(res.data?.isSubjectRegistered))
    await dispatch(updateSubject(
      {...res.data?.subject,
        trainingCompleted: res.data?.trainingCompleted
      }, 
      userId))
    await dispatch(updateUserStudySite(res.data?.studySite, userId))
    moment.tz.setDefault(res.data?.subject?.timeZone)
  } catch (error) {
    console.log(error)
  }
}

import api from '../utils/api';
import formPrintApi from '../utils/formPrintApi';
import constants from '../constants/constants';
import { getRequestPayloadOnline } from './syncQueue';
import { getUserId, getUserType } from '../selectors/user';
import { getPrimaryOrgCode, getSubject } from '../selectors/commonSelector';
const { ContextProperties: {PRIMARY_ORG_CODE, STUDY_ID, SITE_ID} } = constants;
import _ from 'lodash'

export const sendPrintData = async (printRequest) => {
  try {
    const res = await formPrintApi.post(`/${printRequest.primaryOrgCode}/studies/${printRequest.studyId}/sites/${printRequest.siteId}/forms/${printRequest.formId}/print`, printRequest)
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const uploadImageToBucket = async (formData) => {
  try {
    const res = await api.post(`/upload/image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const submitForm =(crfVersionId, subjectVisitId, subjectId, svf) => async (dispatch, getState) => {
  const userId = getUserId(getState())
  const userType = getUserType(getState())
  const primaryOrgCode = getPrimaryOrgCode(getState())
  const publicKey= _.get(getSubject(getState()), 'publicKey', null)
    const prerequisiteInfo = await getRequestPayloadOnline({
      svf,
      userId,
      subjectId,
      userType,
      primaryOrgCode,
      publicKey
    })
    try {
      const res = await api.post(`datasync/${PRIMARY_ORG_CODE}/studies/${STUDY_ID}/crfVersions/${crfVersionId}/sites/${SITE_ID}/subjects/${subjectId}/subjectVisits/${subjectVisitId}/subjectVisitForms`, prerequisiteInfo.requestBody, {
        headers: prerequisiteInfo.headers
      })
      return res;
    } catch (error) {
      throw error
    }
}


import * as React from 'react'
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  Clipboard,
  Alert,
  SafeAreaView,
} from 'react-native'
import { Header } from 'react-navigation-stack'
import {
  GiftedChat,
  Send,
  Bubble,
  MessageText,
  Time,
  Actions,
  Message,
  InputToolbar,
} from 'react-native-gifted-chat'
import {
  MaterialIcons,
  MaterialCommunityIcons,
} from '@expo/vector-icons'
import * as DocumentPicker from 'expo-document-picker'
import * as Linking from 'expo-linking'
const { width } = Dimensions.get('window')
import { buttonBackgroundColor } from '../containers/NavigationScreens'
import AttachedFilePreview from './AttachedFilePreview'
import { getFileType } from '../utils/chatUtils'
import api from '../utils/api'
import WebAlert from '../utils/WebAlert'
import constants from '../constants/constants'
import PropTypes from 'prop-types'
import uuid from 'react-uuid'

// TODO: add firebase?

class Conversation extends React.Component {
 
  state = {
    imageError: false,
    openAttachmentList: false,
    sizeExceded: false,
    filesLengthExceeded: false,
    deleteMessageId: '',
    isAlertModalVisible: false,
  }

  hideAttachmentList = () => {
    this.setState({
      openAttachmentList: false,
    })
  }

  renderSend(props) {
    return (
      <Send {...props}>
        <View
          style={{
            marginLeft: 15,
            marginRight: 15,
            padding: 7,
            backgroundColor: buttonBackgroundColor,
            borderRadius: 50,
          }}
          testID={'conversation-send-message-view'}
          accessible={true}
        >
          <MaterialIcons name="send" size={22} color={'#fff'} />
        </View>
      </Send>
    )
  }

  renderBubble(props) {
    return (
      <Bubble
        {...props}
        wrapperStyle={{
          left: {
            backgroundColor: '#ffffff',
            marginTop: 10,
            borderColor: '#3a354133',
            borderWidth: 1,
          },
          right: {
            backgroundColor: '#9155FD',
            color: '#ffffff',
            marginTop: 10,
          },
        }}
      />
    )
  }

  renderChatFooter = () => {
    ;<View style={{ paddingVertical: 20 }}></View>
  }

  renderInputToolBar = (props) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          backgroundColor: 'transparent',
          borderWidth: 0,
          // paddingTop: 6,
          borderTopColor: 'transparent',
          marginBottom: 10,
          // paddingBottom: 15,
        }}
        primaryStyle={{
          alignItems: 'center',
        }}
      />
    )
  }

  renderMessageText(props, selectedChatId) {
    const currentMessage = props.currentMessage
    const openAttachment = async (messageId) => {
      try {
        const res = await api.get(`/${constants.ContextProperties.PRIMARY_ORG_CODE}/studies/${constants.ContextProperties.STUDY_ID}/sites/${constants.ContextProperties.SITE_ID}/chats/${selectedChatId}/messages/${messageId}/publicUrls?subjectId=${constants.ContextProperties.SUBJECT_ID}`)
        const url = res.data.url
        if (Platform.OS == 'web') {
          window.open(url, '_blank')
        } else {
          Linking.openURL(url)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (currentMessage.messageType !== 'FILE') {
      return (
        <MessageText
          {...props}
          textStyle={{
            right: {
              color: '#ffffff',
            },
          }}
        />
      )
    } else {
      return (
        <View
          style={{
            flexShrink: 1,
            paddingTop: 20,
            paddingHorizontal: 10,
            paddingBottom: 10,
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
          testID={'conversation-open-attachment-view'}
          accessible={true}
        >
          <MaterialCommunityIcons
            name="paperclip"
            size={24}
            color={props.position === 'right' ? '#ffffff' : buttonBackgroundColor}
          />
          <Text
            numberOfLines={1}
            onPress={() => openAttachment(currentMessage._id)}
            style={{
              color: props.position === 'right' ? '#ffffff' : '#0277bd',
              paddingLeft: 5,
              flexWrap: 'wrap',
            }}
          >
            {currentMessage.fileName}
          </Text>
        </View>
      )
    }
  }

  renderMessage(props) {
    const currentMessage = props.currentMessage
    if (currentMessage.messageType !== 'FILE') {
      return <Message {...props} />
    } else {
      return <Message {...props} />
    }
  }

  renderTime(props) {
    return (
      <Time
        {...props}
        timeTextStyle={{
          right: {
            color: '#ffffff',
          },
        }}
      />
    )
  }

  renderActions(props) {
    const options = {
      'Attach File': async (props) => {
        console.log('pick image')
      },
      Cancel: () => {
        console.log('cancel')
      },
    }
    return (
      <Actions
        {...props}
        // options={options}
        icon={() => (
          <MaterialCommunityIcons name="paperclip" size={24} color={buttonBackgroundColor} />
        )}
      >
        {/* <TouchableOpacity onPress={() => alert('Attach!')}>
                        <MaterialCommunityIcons name="paperclip" size={24} color="black" />
                    </TouchableOpacity> */}
      </Actions>
    )
  }

  pickAttachment = async () => {
    const { addFiles, fileList } = this.props
    // if(!openAttachmentList) {
    try {
      this.setState({ openAttachmentList: true })
      const file = await DocumentPicker.getDocumentAsync({
        copyToCacheDirectory: false,
        multiple: true,
      })
      if (file.type === 'success') {
        const fileWithType = {
          ...file,
          type: getFileType(file.name),
          uuid: uuid(),
        }
        var isSizeValid = this.checkSizeExceeded(fileList, file)
        var isLengthValid = fileList.length + 1 <= 5 ? true : false
        if (isSizeValid == true && isLengthValid == true) {
          // alert(JSON.stringify("fileType: "+fileWithType));
          addFiles(fileWithType)
        } else if (isLengthValid == false) {
          this.setState({
            filesLengthExceeded: true,
          })
          setTimeout(() => {
            this.setState({
              filesLengthExceeded: false,
            })
          }, 4000)
        } else if (isSizeValid == false) {
          this.setState({
            sizeExceded: true,
          })
          setTimeout(() => {
            this.setState({
              sizeExceded: false,
            })
          }, 4000)
        }
      }
      console.log(fileWithType)
      // }
    } catch (err) {
      console.log('Cannot pick', err)
      //   alert(JSON.stringify(error));
    }
    // } else {
    //     this.hideAttachmentList();
    // }
  }

  // openAttachment = async(messageId) => {
  //     try {
  //         const res = await api.get(`/chat/publicUrl/generate/${messageId}`);
  //         const url = res.data.url;
  //         Linking.openURL(url);
  //     } catch(error) {
  //         console.log(error)
  //     }
  // }

  onMessageSent = (newMessages) => {
    const { onSend } = this.props
    onSend(newMessages)
    this.hideAttachmentList()
  }

  onLongPressMessage = (chatContext, currentMessage) => {
    const { userId, screenProps: {t} } = this.props
    let options = [ `${t('CopyText')}`, `${t('Cancel')}`]
    if (currentMessage.user._id.toLowerCase() === userId.toLowerCase()) {
      options = [`${t('CopyText')}`, `${t('DeleteMessage')}`, `${t('Cancel')}`]
      const cancelButtonIndex = options.length - 1
      chatContext.actionSheet().showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => this.onLongPressSentMessage(buttonIndex, currentMessage)
      )
    } else {
      const cancelButtonIndex = options.length - 1
      chatContext.actionSheet().showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => this.onLongPressRecievedMessage(buttonIndex, currentMessage)
      )
    }
  }

  onLongPressSentMessage = (buttonIndex, currentMessage) => {
    switch (buttonIndex) {
      case 0:
        Clipboard.setString(currentMessage.text)
        break
      case 1:
        this.showDeleteAlert(currentMessage)
        break
    }
  }

  showDeleteAlert = (currentMessage) => {
    const {screenProps: {t}} = this.props
    if (Platform.OS != 'web') {
      Alert.alert(
        t('DeleteMessage'),
        t('DeleteMessageWarning'),
        [
          {
            text: t('Cancel'),
            onPress: () => console.log('Cancel Pressed'),
            style: 'cancel',
          },
          { text: t('Delete'), onPress: () => this.deleteMessage(currentMessage._id) },
        ],
        { cancelable: false }
      )
    } else {
      this.setState(
        {
          deleteMessageId: currentMessage._id,
        },
        () => {
          this.setState({
            isAlertModalVisible: true,
          })
        }
      )
    }
  }

  onLongPressRecievedMessage = (buttonIndex, currentMessage) => {
    switch (buttonIndex) {
      case 0:
        Clipboard.setString(currentMessage.text)
        break
    }
  }

  deleteMessage = (id) => {
    const { deleteMessage: loDeleteMessage } = this.props
    const { deleteMessageId } = this.state
    if (Platform.OS != 'web') {
      loDeleteMessage(id)
    } else {
      loDeleteMessage(deleteMessageId)
      this.setState({
        isAlertModalVisible: false,
      })
    }
  }

  checkSizeExceeded = (fileList, file) => {
    var maxBytes = 0
    var mb20 = 20971520
    fileList.map((value) => {
      maxBytes = maxBytes + value.size
    })
    maxBytes = maxBytes + file.size

    if (maxBytes > mb20) {
      return false
    } else {
      this.setState({
        sizeExceded: false,
      })
      return true
    }
  }

  hideModal = () => {
    this.setState({
      isAlertModalVisible: false,
    })
  }

  render() {
    const {
      chatMessages,
      onSend,
      userId,
      fetchMoreChatMessages,
      loadingMore,
      fileList,
      removeAttachment,
      sendOnlyAttachments,
      screenProps,
      selectedChatId,
      screenProps: {t}
    } = this.props
    const {
      openAttachmentList,
      sizeExceded,
      filesLengthExceeded,
      isAlertModalVisible,
      deleteMessageId,
    } = this.state
    const buttons = [ 
      { 'title': t('NO'), 'action': () => this.hideModal() }, 
      { 'title': t('YES'), 'action': () => this.deleteMessage(this.state.deleteMessageId) } 
  ]
    return (
      <SafeAreaView style={styles.container}>
      <KeyboardAvoidingView
        behavior={Platform.OS === 'ios' ? 'height' : 'padding'}
        style={styles.container}
        accessible={true}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 120 : -80}
      >
          <View style={{ flex: 1 }}>
            <GiftedChat
              inverted={true}
              isKeyboardInternallyHandled={false}
              textInputStyle={{
                // borderWidth: 1,
                // borderColor: '#bdbdbd',
                borderRadius: 50,
                paddingHorizontal: 20,
                paddingVertical: 5,
                backgroundColor: '#d7dadb',
                outlineStyle: 'none'
              }}
              renderAvatar={null}
              // loadEarlier

              // listViewProps={{
              //     inverted: false,
              //     onEndReached: fetchMoreChatMessages,
              //     onEndReachedThreshold: 0.4,
              // }}
              renderUsernameOnMessage={true}
              {...{ messages: chatMessages, onSend: this.onMessageSent }}
              user={{
                _id: userId.toLowerCase(),
              }}
              alwaysShowSend
              renderSend={this.renderSend}
              renderBubble={this.renderBubble}
              renderMessageText={(msgProps) => this.renderMessageText(msgProps, selectedChatId)}
              renderTime={this.renderTime}
              renderActions={this.renderActions}
              onPressActionButton={this.pickAttachment}
              infiniteScroll={true}
              onLoadEarlier={fetchMoreChatMessages}
              isLoadingEarlier={loadingMore}
              renderMessage={this.renderMessage}
              onLongPress={this.onLongPressMessage}
              // renderInputToolBar={this.renderInputToolBar}

              renderInputToolbar={(props) => this.renderInputToolBar(props)}
              // bottomOffset={50}
              minInputToolbarHeight={60}
              placeholder={`${t('ChatMessagePlaceholder')}...`}
            />
            {isAlertModalVisible && Platform.OS == 'web' && (
              <WebAlert
                t={screenProps.t}
                headerText={t('DeleteMessage')}
                message={t(('DeleteMessageWarning'))}
                hideModal={this.hideModal}
                action={() => this.deleteMessage(deleteMessageId)}
                buttons = {buttons}
              />
            )}
            {openAttachmentList && fileList.length > 0 ? (
              <AttachedFilePreview
                fileList={fileList}
                pickAttachment={this.pickAttachment}
                removeAttachment={removeAttachment}
                hideAttachmentList={this.hideAttachmentList}
                sendOnlyAttachments={sendOnlyAttachments}
                t={t}
              />
            ) : (
              <View />
            )}
            {sizeExceded && (
              <Text
                style={{
                  color: 'white',
                  backgroundColor: 'red',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                }}
              >
                {t('FileSizeExceededWarning')}
              </Text>
            )}
            {filesLengthExceeded && (
              <Text
                style={{
                  color: 'white',
                  backgroundColor: 'red',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                }}
              >
                {t('FilesCountExceededWarning')}
              </Text>
            )}
          </View>
      </KeyboardAvoidingView>
      </SafeAreaView>
    )
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  video: {
    width: width / 1.5,
    height: 150,
    margin: 13,
    borderRadius: 13,
  },
})

Conversation.defaultProps = {
  screenProps: {
    t: ()=> null
  },
  selectedChatId: null,
  userId: null,
}

Conversation.propTypes = {
  screenProps: PropTypes.shape({
    t: PropTypes.func
  }),
  selectedChatId: PropTypes.string,
  userId: PropTypes.string
}

export default Conversation

import React from "react"
import { StyleSheet, View } from "react-native"
import { Slider } from '@react-native-assets/slider'
import PropTypes from 'prop-types'
import { Colors } from "../../../common/ColorScheme"
import constants from "../constants"

const { SHOW_MARKS} = constants;

function CustomThumbComponent({value, selectedValue, showMarks}) {
    return (
        <View style={{
            backgroundColor: (value === selectedValue || showMarks === SHOW_MARKS.SHOW_MARKS) ? 'transparent' : Colors.lightGrey,
            width: 5,
            height: 5
        }} />
    )
}
CustomThumbComponent.defaultProps = {
  value: null,
  selectedValue: null
}

CustomThumbComponent.propTypes = {
  value: PropTypes.number,
  selectedValue: PropTypes.number,
  showMarks: PropTypes.string.isRequired

}

function VerticalScale(props) {
    const {  trackComponent, originalStep, showMarks} = props

    const _renderThumb = (obj) => {
        return <CustomThumbComponent value={obj.value} selectedValue={props.value} showMarks={showMarks}/>
    }
    return (
        <View style={styles.container}>
            <Slider 
                {...props}
                CustomMark={(obj) => trackComponent({index: obj.value})}
                value={props.value != null ? props.value : props.minimumValue + (Number(originalStep) - 0.2)} // temp solution to make the min value selectable
                thumbSize={5}
                thumbTintColor = {"transparent"}
                enabled={!props.disabled}
                CustomThumb={(obj) => _renderThumb(obj)}
            />
        </View>
    )
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
})
VerticalScale.defaultProps = {
    trackComponent: ()=> null,
    value: null,
    disabled: false,
  }
  VerticalScale.propTypes = {
    trackComponent: PropTypes.func,
    value: PropTypes.number,
    minimumValue: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    originalStep: PropTypes.number.isRequired,
    showMarks: PropTypes.string.isRequired

  }
export default VerticalScale
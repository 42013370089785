import React from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import { FcCalendar } from 'react-icons/fc'
import moment from 'moment'
import { StyleSheet, Dimensions} from 'react-native'
import './customDatePickerWidth.css'
import './DatePicker.css'
import constants from '../../constants/constants'
import { connect } from 'react-redux'
import CustomTimeView from './CustomTimeView'
import _ from 'lodash'
const { dateFormats, datepickerMode } = constants;

const { width: SCREEN_WIDTH } = Dimensions.get('window');
const SCALE_FACTOR = SCREEN_WIDTH / 375;
function CustomInput({ onClick, date, isDiaryDatePicker }) {
  return (
    <button 
      type="button" 
      onClick={onClick} 
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick(e);
        }
      }}
      className="dateInput" 
      style={styles.customDateInput}
    >
      <div style={styles.iconWrapper}>
        <FcCalendar size="1.1em" />
      </div>
      <div style={styles.dateText}>{isDiaryDatePicker ? date : moment(date).format('DD-MMM-yyyy')}</div>
    </button>
  );
}

CustomInput.defaultProps = {
  onClick: () => {},
  date: null,
  isDiaryDatePicker: false
}

CustomInput.propTypes = {
  onClick: PropTypes.func,
  date: PropTypes.string,
  isDiaryDatePicker: PropTypes.bool
}

function CustomDateInput({
  onChange,
  date,
  showTimeInput,
  timeFormat,
  valueFormat,
  t,
  locale,
  isDiaryDatePicker,
  disabled,
  onMobile,
  selectedDateTime
 }) {

  let datePickerClass;

  if (SCREEN_WIDTH >= 1024) {
    datePickerClass = 'webdatepk15';
  } else if (SCREEN_WIDTH >= 750) {
    datePickerClass = 'webdatepk22';
  } else if (SCREEN_WIDTH > 350) {
    datePickerClass = 'webdatepk12';
  } else {
    datePickerClass = 'webdatepk10';
  }

  function renderMonth(props, month, year, selectedDate) {
    const monthName = moment.months(month)
    const isMobile = onMobile && SCREEN_WIDTH <= 768;
    return <td  
      style={{
      ...styles.customDateFont,
      ...(isMobile && styles.mobileFontSize),
      }} {...props}>{t(monthName)}</td>
  }

  moment.updateLocale(locale, {
    weekdaysMin: moment.localeData(locale).weekdaysShort(),
    postformat: function (string) {
      return string.replace(/\d/g, (match) => '0123456789'[match]);
    },
    ...(locale === constants.locale.hindi && {
      meridiem: function (hour, minute, isLower) {
        if (hour < 12) {
          return t('AM')
        } else {
          return t('PM')
        }
      },
    }),
  });
  moment.locale(constants.locale.english)
  
  const handleTimeChange = (newTime) => {
    const parsedDate = selectedDateTime ? moment(selectedDateTime).format(dateFormats.DATE) : moment().format(dateFormats.DATE)
    const loDate = parsedDate+ ' '+ newTime
    onChange(moment(loDate, valueFormat).valueOf())
  };

  const renderCustomView = (mode, renderCalendar) => {
    if (mode === datepickerMode.time) {
      return (
        <CustomTimeView
          selectedDateTime={(selectedDateTime && moment(selectedDateTime).format(valueFormat))|| moment()}
          onTimeChange={handleTimeChange}
          t={t}
          timeFormat = {timeFormat}
          locale={locale}
          valueFormat={valueFormat}
        />
      );
    }
    return renderCalendar(mode);
  };

  const onValueChange = (selectedDate) => {
    if(isDiaryDatePicker){
      onChange(moment(selectedDate.toDate()).format(valueFormat))
      return;
    }
    let updatedDateTime = moment(selectedDate).locale(constants.locale.english).format(valueFormat)
    updatedDateTime = moment(updatedDateTime, valueFormat)
    if (_.isEmpty(date) && showTimeInput) {
      updatedDateTime = updatedDateTime
        .hour(moment().hour())
        .minute(moment().minute())
        .second(moment().second());
    }
    onChange(moment(updatedDateTime, valueFormat).valueOf())
  }


  return (
    <div 
      className="customWdth"
      style={styles.wrapper}
    >
      <Datetime
        renderMonth={renderMonth}
        initialValue={selectedDateTime ? moment(selectedDateTime) : moment()}
        dateFormat={valueFormat}
        timeFormat={showTimeInput ? timeFormat : false}
        className={datePickerClass}
        onChange={(selectedDate) => 
          onValueChange(selectedDate)
        }
        renderView={renderCustomView}
        renderInput={(p,openCalendar) => (
          isDiaryDatePicker &&
          <CustomInput date={date} onClick={openCalendar} isDiaryDatePicker={isDiaryDatePicker}/>
        )}
        closeOnSelect={true}
        inputProps={{
          className: 'inputProps',
        }}
        initialViewMode="days"
        viewMode="days"
        locale={locale}
        portalId="root-calendar"
        input={isDiaryDatePicker}
        disabled={disabled}
      />
    </div>
  )
}


  const styles = StyleSheet.create({
    customDateInput: {
      width: 180,
      border: 'none',
    },
    iconWrapper: {
      display: 'inline-block',
      paddingRight: 10,
    },
    dateText: {
      display: 'inline-block',
    },
    wrapper: {
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    customDateFont: {
      fontFamily: 'Inter',
    },
    mobileFontSize: {
      fontSize: 14 * 0.75 * SCALE_FACTOR,
    },
  })

  CustomDateInput.defaultProps = {
  onChange: () => {},
  date: '',
  showTimeInput: false,
  timeFormat: moment.localeData().longDateFormat("LT"),
  valueFormat: dateFormats.DATE,
  disabled: false,
  isDiaryDatePicker: false,
  onMobile: false,
  selectedDateTime: '',

}

CustomDateInput.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.string,
  showTimeInput: PropTypes.bool,
  timeFormat: PropTypes.string,
  valueFormat: PropTypes.string,
  disabled: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isDiaryDatePicker: PropTypes.bool,
  onMobile: PropTypes.bool,
  selectedDateTime: PropTypes.string,
}

const mapStateToProps = (state) => ({
  onMobile: state.appStatus.onMobile,
})

export default connect(mapStateToProps, null)(CustomDateInput)

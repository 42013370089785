
import forge from 'node-forge';

const generateAESKey = async () => {
    return forge.random.getBytesSync(32); // 256 bits = 32 bytes
};

function encryptWithAESGCM(aesKey, plainText) {
    const iv = forge.random.getBytesSync(12); // 16 bytes = 128 bits (for AES block size)
    const salt = forge.random.getBytesSync(16); // 16 bytes = 128 bits (for AES block size)
    const cipher = forge.cipher.createCipher('AES-GCM', aesKey);
    
    const options = {
        iv: iv,
        tagLength: 128,
    };
    cipher.start(options);
    cipher.update(forge.util.createBuffer(plainText, 'utf8'));
    cipher.finish();

    const combinedBuffer = forge.util.createBuffer();
    combinedBuffer.putBytes(iv); // Add IV first (12 bytes)
    combinedBuffer.putBytes(salt); // Add Salt (16 bytes)
    combinedBuffer.putBytes(cipher.output.getBytes()); // Add Ciphertext
    combinedBuffer.putBytes(cipher.mode.tag.getBytes()); // Add Tag

    return forge.util.encode64(combinedBuffer.getBytes())
}
const encryptWithRSA = async (aesBuffer, publicKeyBase64) => {
    const pemPublicKey = `-----BEGIN PUBLIC KEY-----\n${publicKeyBase64}\n-----END PUBLIC KEY-----`;
    const publicKey = forge.pki.publicKeyFromPem(pemPublicKey);
    const aesEncData = forge.util.encode64(aesBuffer);
    const schemeOptions = {
        md: forge.md.sha256.create(),
        mgf1: {
            md: forge.md.sha256.create()
        }
    };
    const encrypted = publicKey.encrypt(aesEncData, 'RSA-OAEP', schemeOptions);
    console.log('Encrypted Data (Base64):', forge.util.encode64(encrypted));
    return forge.util.encode64(encrypted);
}
export const encryptPayload = async (data, publicKey) => {
    const aesKey = await generateAESKey();
    const aesEncData = encryptWithAESGCM(aesKey, JSON.stringify(data));
    const aesBuffer = forge.util.createBuffer(aesKey).getBytes();
    const rsaEncryptedAesKey = await encryptWithRSA(aesBuffer, publicKey);
    return { aesEncData: aesEncData.toString(), rsaEncryptedAesKey, aesKey };
};


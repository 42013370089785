import React, { useMemo } from "react";
import { Platform, StyleSheet } from "react-native";
import _ from 'lodash';
import { alignments } from "../../scales/alignments";
import PropTypes from 'prop-types';
import { AutoSizeText, ResizeTextMode } from "react-native-auto-size-text";
import constants from "./constants";
import { isInSequence } from "../../scales/utils";
import ClippedText from "./ClippedText";
const {SHOW_MARKS} = constants
const TrackMarkNumbers=(props) => useMemo(() => {
    const {indicationConfig: {indicationMultiple, indicationNumbers}, index, orientation,
    showMarks, step, min, max, isPercentage, requiredFontForMarks, numberOfTerms, value,
    scaleProps: {scaleWidth, widthOfBox}, onMobile
} = props
    const length = (Number(max)-Number(min))/Number(step)
    const displayNumber = `${index}${isPercentage?'%':''}`
    const maxNumber = `${max}${isPercentage?'%':''}`
    const containsDigit = () => {
        const arr = indicationNumbers.split(',')
        const digitStr = String(index);
        return arr.some(numStr => _.isEqual(numStr, digitStr));
    }
    const showNumber=()=> {
        return ((_.isNumber(indicationMultiple) && (index % indicationMultiple === 0)) || (!_.isEmpty(indicationNumbers) && containsDigit()) || (!_.isNumber(indicationMultiple) && _.isEmpty(indicationNumbers) && (index % 5 === 0)))
    }
    //is the same index already shown by trackmark component then we are not showing mark, only showing number
    const isMarkThere = () =>{
        if(value === index){
            return true;
        }
        switch(showMarks){
            case SHOW_MARKS.SHOW_MARKS: return isInSequence(index, min, max, step)
            case SHOW_MARKS.SHOW_EDGE_MARKS: return (index=== min || index===max) 
            case SHOW_MARKS.NO_MARKS: return index === value
        }
    }
    const getMarginLeft = () => {
        if(orientation === alignments.HORIZONTAL){
            return getMarginLeftBasedOnPlatform()
        }else{
            return (isMarkThere() ? 40 : 23)
        }
    }
    const getMarginTop = () => {
        if(orientation === alignments.HORIZONTAL){
            return isMarkThere() ? 35 : 20
        }else if(orientation === alignments.VERTICAL){
            if(length>=99 && (_.isNumber(indicationMultiple) && indicationMultiple < 4)){
                return -2
            }else{
                return -5
            }
        }
    }

    const getMarginLeftBasedOnPlatform = () => {
        if (length > 100 || numberOfTerms > 100) {
            return 0;
        }
        const isWeb = Platform.OS === 'web';

        if (SHOW_MARKS.SHOW_EDGE_MARKS === showMarks && isPercentage) {
            return isWeb ? -4 : -20;
        }
        if (SHOW_MARKS.NO_MARKS === showMarks) {
            return isWeb ? -4 : -3 * getScaleFactor();
        }
        if (isWeb) {
            if (isPercentage) {
                return -7;
            }
            if (length > 20) {
                return -7;
            }
            return -3;
        }
        return isPercentage ? - 7 : -3;
    }

    const getScaleFactor = () => {
        if(numberOfTerms>15 && Platform.OS !== 'web'){
            const minLength = displayNumber.length > 2 ? displayNumber.length : 3
            return 0.9*(minLength/maxNumber.length)
        }else if(Platform.OS !== 'web' && numberOfTerms <= 25){
            return numberOfTerms>10 ? 2.5/maxNumber.length : 2/maxNumber.length
        } else if (onMobile && numberOfTerms > 25){
            return (20/numberOfTerms)*(displayNumber.length/maxNumber.length)
        }
        return 1
    }

    const getVerticalMarginLeft = () => {
        const displayNumberLength = String(displayNumber).length
        if(Number(index) === Number(min)){
            return -requiredFontForMarks*(displayNumberLength*0.2)
        } else if (Number(index) === Number(max)){
            return -requiredFontForMarks*(displayNumberLength*0.4)
        }
        return -requiredFontForMarks*(displayNumberLength*0.3)
    }
    const getStyle = () => {
         if(orientation === alignments.HORIZONTAL){
            return {
                ...styles.hzTextStyle,
                marginTop: getMarginTop(),
                 marginLeft: getMarginLeft(),
                 width: scaleWidth/numberOfTerms+1,
                 transform: [{ scale: getScaleFactor() }],
                 paddingTop: displayNumber.length > 3 && !isMarkThere() && Platform.OS !== "web" ? 1 : 0,
            }
        } else if (orientation === alignments.MOBILE_VERTICAL) {
            return {
                color: '#000000',
                transform: [{ rotate: '90deg' }],
                marginBottom: -10,
                marginLeft: getVerticalMarginLeft(),
                marginTop: 10
            }
        } else{
            const displayNumberLength = String(displayNumber).length

            return {
                ...styles.vtTextStyle,
                marginLeft: getMarginLeft(),
                marginTop: getMarginTopForVertical(displayNumberLength)
            }
    }
}


const getMarginTopForVertical = (displayNumberLength) => {
    if(Number(index) === Number(min)){
        return -7
    }
    if(Number(index) === Number(max)){
        return -4
    }
    if(((length>=99 && numberOfTerms>21) && displayNumberLength < 4)){
        return -2
    }else if((displayNumberLength >=4 && max > 100 && length > 20)){
        return 0
    }else{
        return -4
    }
}


const getWebFontSize = () => {
    if(!onMobile){
        return (numberOfTerms > 15) ? requiredFontForMarks - 1: requiredFontForMarks;
    }
    return (numberOfTerms > 15) ? requiredFontForMarks - 3: requiredFontForMarks;
}
    if(showNumber()){
            return Platform.OS === 'web' ? (
                <ClippedText 
                text={displayNumber}
                width = {widthOfBox}
                height = {30} 
                fontSize = {getWebFontSize()}
                style = {getStyle()}
                containerStyle={{ minWidth: orientation === alignments.HORIZONTAL ? widthOfBox : 200, height: 30, position: "absolute" }}
                 orientation = {orientation}
                />) : 
                (
                <AutoSizeText
                numberOfLines={1}
                fontSize={requiredFontForMarks}
                mode={ResizeTextMode.min_font_size}
                style={getStyle()}
                minFontSize = {1}
                >
                {displayNumber}
                </AutoSizeText>
                )
    }
    return <></>
}, [props.index, props.id, props.value])
const styles = StyleSheet.create({
    hzTextStyle: {
        position: 'absolute',
        color: '#000000'
    },
    vtTextStyle: {
       position: 'absolute',
        color: '#000000',
        marginRight: 5,
    },
  });
TrackMarkNumbers.defaultProps = {
    indicationConfig: {
        indicationMultiple: null,
        indicationNumbers: null
    },
    index: 0.1,
    showMarks: SHOW_MARKS.NO_MARKS,
    orientation: alignments.HORIZONTAL,
    step: 1,
    min: 0,
    max: 10,
    isPercentage: false,
    id: null,
    scaleProps: {},
    onMobile: true
  }
TrackMarkNumbers.propTypes = {
    isPercentage: PropTypes.bool,
    indicationConfig: PropTypes.shape({
        indicationMultiple: PropTypes.number,
        indicationNumbers: PropTypes.string,
    }),
    showMarks: PropTypes.string,
    orientation: PropTypes.string,
    step: PropTypes.number,
    min: PropTypes.number,
    max: PropTypes.number,
    index: PropTypes.number,
    id: PropTypes.string,
    scaleProps: PropTypes.shape({
        scaleWidth: PropTypes.number,
        widthOfBox: PropTypes.number,
    }),
    onMobile: PropTypes.bool,
  }
export default TrackMarkNumbers
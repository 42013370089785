import {
  RETRIEVE_ALL_CRF_DATA_FAILURE,
  RETRIEVE_ALL_CRF_DATA_REQUEST,
  RETRIEVE_ALL_CRF_DATA_SUCCESS,
  UPDATE_FIELD_ANSWER,
} from '../actions/crfData'
import initialState from '../store/initialState'
import { CLEAR_USER_STORAGE } from '../actions/syncQueue'
import moment from 'moment'
export default (state = initialState.crfData, action) => {
  switch (action.type) {
    case RETRIEVE_ALL_CRF_DATA_REQUEST:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          loading: true,
        }
      }
    case RETRIEVE_ALL_CRF_DATA_FAILURE:
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          loading: false,
        }
      }
    case RETRIEVE_ALL_CRF_DATA_SUCCESS: {
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
          ...action.formAnswers,
          loading: false,
        }
      }
    }
    case UPDATE_FIELD_ANSWER: {
      const fieldId = Object.keys(action.selectedField)[0]
      const selectedFieldValue = action.selectedField[fieldId]
      return {
        ...state,
        [action.userId]: {
          ...state?.[action.userId],
        [action.svfId]: {
          ...state?.[action.userId]?.[action.svfId],
          [fieldId]: {
            ...state?.[action?.userId]?.[action?.svfId]?.[fieldId],
            fieldValue: selectedFieldValue,
            optionOid: selectedFieldValue,
            submissionTs: moment.utc().format(),
          }
        }
      }
    }
    }
    case CLEAR_USER_STORAGE: 
      return {
        ...state,
        [action.userId]: {}
      }
    default:
      return state
  }
}

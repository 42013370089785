import React from "react"
import {  View, StyleSheet, PixelRatio, Platform} from "react-native"
import _ from 'lodash'
import { isInSequence } from "../../scales/utils"
import PropTypes from 'prop-types'
import { getLabel, getLabelWidth, getValue } from "./VerticalLabelUtils"
import { fontScale } from "../../styles/fontResizer"
import LabelTextDisplay from "./LabelTextDisplay"
import { alignments } from "../../scales/alignments"

const VerticalLabels = (props) => {
    const {labels, step, start, end, labelFlex , disabled, id, scaleProps, transformedFont, labelsData, hasStartOrEndLabel, onMobile} = props
    const {widthOfBox, scaleWidth} = scaleProps
    let textAlign = 'right'
    let boxAlign = 'flex-end'
    if(_.find(labels, label => label.labelPlacement === 'RIGHT')){
        textAlign = 'left'
        boxAlign = 'flex-start'
    }

    const getMarginForWideBox = (isStart, isEnd, isTextFit, valueLength, fontSize) => {
        if (isStart && isTextFit) {
            return  (onMobile ? fontSize * 1.3 : fontSize * 1.4)
        }
        if (isStart && !isTextFit) {
            return  (onMobile ? fontSize * 2.5 : fontSize * 1.4)
        }
        if (isEnd && valueLength < 120) {
            return fontSize * 1.5
        }
        if (isEnd && valueLength >= 120) {
            return (onMobile ? fontSize * 0.9 : fontSize * 1.4)
        }
        return fontSize * 1.5
    }

    const getBaseMultiplier = (valueLength, isTextFit) => {
        if (valueLength < 120) {
            return isTextFit ? 1 : 1.6;
        }
        if (valueLength < 800) {
            return 0.6;
        }
        return 0.4;
    }
    const getMarginTopBasedOnBoxWidth = (fontSize, label, isTextFit) => {
        if (!label) {
            return onMobile ? fontSize * 1.9 : fontSize
        }

        const position = Number(label.position)
        const valueLength = label?.value?.length || 0
        const isStart = position === Number(start)
        const isEnd = position === Number(end)

        if (widthOfBox > 10) {
            return getMarginForWideBox(isStart, isEnd, isTextFit, valueLength, fontSize)
        }

        if (isStart) {
            const baseMultiplier = getBaseMultiplier(valueLength, isTextFit)
            return fontSize * baseMultiplier
        }

        if (isEnd) {
            return fontSize
        }

        return onMobile ? fontSize * 1.9 : fontSize
    }

    const isMinLengthWithEndPosition = (valueLength, isEndPosition) => {
        return valueLength < 200 && isEndPosition
    }

    const isMinLengthWithStartPosition = (valueLength, isEndPosition) => {
        return valueLength < 200 && !isEndPosition
    }

    const getTextMarginTop = (label, isTextFit) => {
        const fontSize = transformedFont || 10;
        const isWeb = Platform.OS === 'web';
        
        const valueLength = label?.value?.length || 0;
        const isEndPosition = Number(label?.position) === Number(end);

        if (!hasStartOrEndLabel) {
            return isWeb ? -fontSize * 0.4 : -fontSize * 0.9;
        }

        if (isMinLengthWithEndPosition(valueLength, isEndPosition)) {
                return isWeb ? getMarginTopBasedOnBoxWidth(fontSize, label, isTextFit) : -fontSize * 0.5;
        }

        if (isMinLengthWithStartPosition(valueLength, isEndPosition)) {
            return isWeb ? getMarginTopBasedOnBoxWidth(fontSize, label, isTextFit) : -fontSize * 1;
        }

        if (valueLength < 800) {
            return isWeb ? fontSize * 0.8 : -fontSize * 0.8;
        }

        return isWeb ? -fontSize * 0.2 : -fontSize * 0.8;
    }

    const getTextStyles = (label, isTextFit) => {
       return {
            textAlign: textAlign, 
            marginTop:  label ? getTextMarginTop(label, isTextFit) : 0,
            marginRight: Platform.OS !== 'web' ? -20 : 0,
        }
    }

    const getBoxStyles = (number) => {
        return {
            ...styles.box,
            alignItems: boxAlign,
            height: getBoxHeight(number),
            marginTop: getMarginTop(number),
        }
    }

    const getMarginTop = (number) => {
        const arr = _.map(_.range(start, Number(end) + 1, step), number => number)
        if(!isInSequence(end, start, end, step) && number === _.last(arr)){
          //lastButOneNumber where end is not in sequence
          return scaleWidth - (arr.length-1)*widthOfBox
        }
        return 0
    }

    const getBoxHeight =(number) => {
        if(number === start && _.isEmpty(getValue(start, labels))){
            return 0
        }else if(number === start && !_.isEmpty(getValue(start, labels))){
            return widthOfBox>30 ? widthOfBox : 30
        }else{
            return widthOfBox
        }
    }

   return (
            <View style={styles.container}>
            {_.map(_.orderBy(_.range(start, Number(end) + 1, step), [], 'desc'), (number, index) => {
                        const loWidth =  _.get(labelsData[number], 'width', getLabelWidth(labelFlex))
                        const loHeight =  _.get(labelsData[number], 'height', 0)
                        const isTextFit =  _.get(labelsData[number], 'fit', false)
                        const label = getLabel(number, labels);
                        return (
                            <View key={index} style={[getBoxStyles(number), {paddingLeft: 10, paddingRight: 20}]}>
                                <LabelTextDisplay 
                                    isFit={isTextFit || _.isEmpty(label?.value)}
                                    testID={`visual-analog-scale-sliderLabels-${id}-${number}-vtLabel`}
                                    accessible={!disabled} 
                                    text={getValue(number, labels)}
                                    fontsize={transformedFont}
                                    containerWidth={loWidth}
                                    containerHeight={loHeight}
                                    labelStyles={getTextStyles(label, isTextFit)}
                                    orientation={alignments.VERTICAL}
                                    containerStyle={{ height: loHeight}}
                                />
                            </View>
                        )
                    })
               }
        </View>
    );
}



const styles = StyleSheet.create({
    container: { flex: 1},
    box: {},
  });

VerticalLabels.defaultProps = {
    start: 0,
    end: 10,
    step: 1,
    labelFlex: null,
    labels: [],
    disabled: false,
    id: null,
    scaleProps: {
        widthOfBox: 0,
        length: 0,
        scaleWidth: 0,
        fontSize: 10/(PixelRatio.getFontScale()),
        scale: 1,
      },
    labelsData: {}, 
    transformedFont: fontScale(10),
    hasStartOrEndLabel: false
  }
  
VerticalLabels.propTypes = {
    start: PropTypes.number,
    end: PropTypes.number,
    step: PropTypes.number,
    labelFlex: PropTypes.number,
    labels: PropTypes.arrayOf(Object),
    disabled: PropTypes.bool,
    id: PropTypes.string,
    scaleProps: PropTypes.shape({
        widthOfBox: PropTypes.number,
        length: PropTypes.number,
        scaleWidth: PropTypes.number,
        fontSize: PropTypes.number,
        scale: PropTypes.number,
      }),
    labelsData: PropTypes.instanceOf(Object), 
    transformedFont: PropTypes.number,
    hasStartOrEndLabel: PropTypes.bool,
    onMobile: PropTypes.bool.isRequired
}
export default VerticalLabels
import React, { useState } from 'react';
import { View, Modal, TouchableOpacity, Text, StyleSheet } from 'react-native';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';
import PropTypes from 'prop-types';
import './customDatePickerWidth.css'
import './DatePicker.css'
import CustomDateInput from './CustomDateInput';
import {Colors} from '../common/ColorScheme'
import constants from '../../constants/constants';

const {dateFormats} = constants

function CustomInput({onClick, formattedDate, placeHolder}) {
  return (
    <TouchableOpacity onPress={onClick} style={styles.webdateInput}>
      <Text style={styles.webdateInputText}>
        {formattedDate || placeHolder}
      </Text>
    </TouchableOpacity>
  );
}
CustomInput.defaultProps = {
  onClick: () => {},
  formattedDate: null,
  placeHolder: '', 
}

CustomInput.propTypes = {
  onClick: PropTypes.func,
  formattedDate: PropTypes.string,
  placeHolder: PropTypes.string, 
}

function DateModalLayout(props) {
    const { onChange,
        date,
        showTimeInput,
        timeFormat,
        placeHolder,
        valueFormat,
        isInitialValue,
        shortTimezone,
        disabled,
        t,
        locale,
        isDiaryDatePicker} = props

  const [modalVisible, setModalVisible] = useState(false);
  const [tempDate, setTempDate] = useState(date && moment(date, dateFormats.DATETIME).valueOf());

  function handleOk() {
    let formattedDate = ''
    if (tempDate) {
      formattedDate = moment(tempDate).format(valueFormat)
    }
    else{
      formattedDate = moment().format(valueFormat)
    }
    onChange(showTimeInput ? formattedDate +' '+ shortTimezone : formattedDate);
    setModalVisible(false)
  }

  function handleCancel() {
    setTempDate(date && moment(date, dateFormats.DATETIME).valueOf())
    setModalVisible(false);
  }

  function handleDateChange(selectedDate) {
    setTempDate(selectedDate);
  }
  const handleModal = () => {
    if(!disabled){
      setModalVisible(true)
    } 
  }
  return (
    <View>
      <CustomInput formattedDate={date} placeHolder={placeHolder} onClick={handleModal} />
      <Modal transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
        <View style={styles.webdateModalOverlay}>
          <View style={styles.webdateModalContainer}>
            <CustomDateInput
              date={date}
              showTimeInput={showTimeInput}
              timeFormat={timeFormat}
              placeHolder={placeHolder}
              valueFormat={valueFormat}
              isInitialValue={isInitialValue}
              shortTimezone={shortTimezone}
              disabled={disabled}
              t={t}
              locale={locale}
              isDiaryDatePicker={isDiaryDatePicker}
              onChange={handleDateChange}
              selectedDateTime={tempDate}
            />
            <View style={styles.webdateButtonContainer}>
              <TouchableOpacity onPress={handleCancel}>
                <Text style={styles.webdateButton}>{t('Cancel')}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={handleOk}>
                <Text style={styles.webdateButton}>{t('OK')}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  webdateModalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  webdateModalContainer: {
    backgroundColor: Colors.white,
    padding: 20,
    borderRadius: 10,
    width: '90%',
    maxWidth: 500,
  },
  webdateButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 20,
  },
  webdateButton: {
    color: Colors.blue,
    textAlign: 'center',
  },
  webdateInput: {
    flexDirection: 'row',
    padding: 5,
    borderWidth: 1,
    borderColor: Colors.borderColor,
    backgroundColor: Colors.backgroundColor,
    width: 288,
    borderRadius: 5,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  webdateInputText: {
    textAlign: 'center',
    width: '100%',
    color: Colors.darkGrey,
    fontSize: 14,
    fontFamily: 'Inter'
  },
  customDateInput: {
    width: 180,
  },
  iconWrapper: {
    display: 'inline-block',
    paddingRight: 10,
  },
  dateText: {
    display: 'inline-block',
  },
  wrapper: {
    alignContent: 'center',
    width: 185,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

DateModalLayout.defaultProps = {
  onChange: () => {},
  date: '',
  showTimeInput: false,
  timeFormat: 'HH:mm',
  placeHolder: 'Select date',
  valueFormat: 'DD-MMM-YYYY',
  isInitialValue: false,
  shortTimezone: '',
  disabled: false,
  isDiaryDatePicker: false,
};

DateModalLayout.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.string,
  showTimeInput: PropTypes.bool,
  timeFormat: PropTypes.string,
  placeHolder: PropTypes.string,
  valueFormat: PropTypes.string,
  isInitialValue: PropTypes.bool,
  shortTimezone: PropTypes.string,
  disabled: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  isDiaryDatePicker: PropTypes.bool,
};

export default DateModalLayout;

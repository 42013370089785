import NetInfo from '@react-native-community/netinfo'
import React, { Component } from 'react'
import { AppState, Platform, View } from 'react-native'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  storeAppStatus,
  updateDeviceToken,
} from '../actions/storeAppStatus'
import { updateUserTimezone } from '../actions/timeZone'
import { updateIsAcknowledged } from '../actions/session'
import OfflineNotice from '../components/OfflineNotice'
import { checkForDataSync, getOfflineForms } from '../utils/offline/dataSync'
import NavigationScreens from './NavigationScreens'
import coreConstants from '../constants/constants'
import NavigationService from './navigationService'
import moment from 'moment-timezone'
import { getTzDetailsByOfffsetFromName } from '../utils/timezoneUtils'
import AppAlerts from './AppAlerts'
import { storeSyncStatus, updateSyncCompleteStatus } from '../actions/syncQueue'
import { getCurrentScreen } from '../selectors/session'
import { getUserTimezone, getUser, getSubject, getUserSession } from '../selectors/commonSelector'
import PropTypes from 'prop-types'
import _ , {isEqual} from 'lodash'
import CustomSocket from '../components/Socket/CustomSocket'
import CallJoinRequest from './CallJoinRequest'
import { openMeeting } from '../actions/login'
import QuestionnaireUpdate from '../components/Modal/QuestionnairesUpdate/QuestionnaireUpdate'
import { updateStudyMetaData } from '../actions/studyMetaData'
import uuid from 'react-uuid'
import { store } from '../store/configStore'
import { getVersionUpgradeLoader } from '../selectors/studyMetaData'

class AppNavigation extends Component {
  constructor(props) {
    super(props)

    const configuredValue = props?.userTimezone

    this.state = {
      disappear: false,
      isInternetReachable: true,
      errorMessage: '',
      isTimeZoneNoClicked:false,
      callDetails: {
        data: {},
        isJoinCallModalVisible: false
      }
    }

    if (configuredValue) {
      moment.tz.setDefault(configuredValue)
    }
  }

  componentDidMount() {
    const { updateDeviceToken } = this.props
      NetInfo.addEventListener((state) => {
        this.handleConnectivityChange(state)
      })
      if(Platform.OS === 'web') {
        updateDeviceToken(uuid());
      } else {
        AppState.addEventListener('change', this.handleAppStateChange)

      }
      
  }

  handleAppStateChange = async (nextAppState) => {
    if (nextAppState === 'active' && 
    !_.get(getUserSession(store.getState()), 'isQuestionnaireUpgradeRequired', false) && 
    store.getState()?.appStatus?.isDeviceOnline) {
      this.upgradeDiaries(true)
    } 
  }

  upgradeDiaries(forceUpdate=false) {
    const { subject, updateStudyMetaData } = this.props
    if (subject?.id) {
      updateStudyMetaData(forceUpdate)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      updateSyncCompleteStatus,
      appStatus,
      storeSyncStatus,
    } = this.props

    const syncUpdates = {
      isSyncing: null,
      syncCompleted: null,
    }

    const prevAppStatus = {
      ...prevProps.appStatus,
      ...syncUpdates,
    }

    const currentAppStatus = {
      ...appStatus,
      ...syncUpdates,
    }

    const hasAppStatusChanged = !isEqual(prevAppStatus, currentAppStatus)

    if (hasAppStatusChanged) {
      if (coreConstants.AppType.SITESTAFF !== appStatus.appType) {
        checkForDataSync(
          appStatus,
          storeSyncStatus,
          updateSyncCompleteStatus,
        )
      }
    }
  }

  handleConnectivityChange = (state) => {
    const { storeAppStatus, screenProps: { t } } = this.props
    const { type, isInternetReachable, isConnected } = state

    const isNotOnline = ['none', 'unknown'].includes(type) || !isConnected
    const isOnline = isConnected && isInternetReachable

    if (isOnline) {
      storeAppStatus(true)
      this.autoCloseToaster()
      this.setState({
        errorMessage: null,
      })
      if(!_.get(getUserSession(store.getState()), 'isQuestionnaireUpgradeRequired', false)){
        this.upgradeDiaries(true);
      }
    } else if (isNotOnline) {
      storeAppStatus(false)
      this.setState({
        disappear: false,
        errorMessage: t('OfflineMessage'),
      })
      console.log({
        caller: 'AppNavigator.handleConnectivityChange',
        isOnline: false,
      })
    }
  }

  isTimeZoneNoClicked = (val) => {
    this.setState({isTimeZoneNoClicked : val})
  }

  setOrAckTimezoneChange = async (shouldChange) => {
    const { timeZones, updateUserTimezone, updateIsAcknowledged, screenProps:{t} , subject} = this.props
    const inferredValue = moment.tz.guess(true)
    await updateIsAcknowledged(true)
    if (shouldChange) {
      let updatedTimeZone = getTzDetailsByOfffsetFromName(inferredValue, timeZones) || '';
      const loSubject = {
        id: subject.id,
        timeZone: updatedTimeZone && updatedTimeZone.timeZoneName ? updatedTimeZone.timeZoneName : inferredValue ,
        lastUpdatedDateOn: subject?.lastUpdatedDateOn
      }
      await updateUserTimezone(loSubject, t)
    }
  }

  autoCloseToaster = () => {
    setTimeout(() => {
      this.setState({ disappear: true })
    }, 5000)
  }

  onCallJoinRequest = (callDetails) => {
    this.setState(prevState => ({
      ...prevState,
      callDetails: {
       data: callDetails,
       isJoinCallModalVisible: true
      }
    }))
  } 
  closeCallJoinRequestModal = () => {
    this.setState(prevState => ({
      ...prevState,
      callDetails: {
        data: {},
        isJoinCallModalVisible: false
      }
    }))
  }

  openCallLink = () => {
    const { screenProps: { locale } } = this.props  
    this.closeCallJoinRequestModal()
    openMeeting(this.state?.callDetails?.data?.accessCode, locale)
  }


  render() {
    const { screenProps, appStatus,appStatus: { isDeviceOnline: isConnected }, screenProps: { t },navigation, 
    storage, userTimezone, user , currentScreen, isAcknowledged, subject, isQuestionnaireUpdateRequired, 
    isPinValidated, isRefetchingMetaData } = this.props
    const { disappear, isInternetReachable ,isTimeZoneNoClicked, callDetails} = this.state
    const finalScreenProps = {
      ...screenProps,
    }

    return (
      <View style={{ flex: 1, backgroundColor: '#ffffff', width: '99%', marginLeft: 2 }}>
        {disappear === false && Platform.OS !== 'web' && (
          <OfflineNotice
            t={screenProps.t}
            isInternetReachable={isInternetReachable}
            isConnected={isConnected}
          />
        )}
        <AppAlerts
        t={t}
        navigation={navigation}
        appStatus ={appStatus}
        user={user}
        setOrAckTimezoneChange ={this.setOrAckTimezoneChange}
        isTimeZoneNoClicked = {this.isTimeZoneNoClicked}
        configuredValue ={userTimezone}
        isTimeZoneNoClickedProp={isTimeZoneNoClicked}
        isStorageAvailable={storage?.isStorageAvailable}
        isSpaceAlertAcknowledged={storage?.isSpaceAlertAcknowledged}
        currentScreen={currentScreen}
        isAcknowledged={isAcknowledged}
        />
        {
          isPinValidated &&
          <CallJoinRequest
            visible={callDetails.isJoinCallModalVisible}
            closePopup={this.closeCallJoinRequestModal}
            call={callDetails.data}
            openCallLink={this.openCallLink}
            timeZone={userTimezone}
            t={t}
          />
        }
        {isQuestionnaireUpdateRequired && isConnected && isPinValidated &&
        <QuestionnaireUpdate 
          screenProps={finalScreenProps} 
          appType = {appStatus?.appType} 
          isRefetchingMetaData = {isRefetchingMetaData}
          userId = {user?.id}
        />
      }
        <CustomSocket 
          user = {user} 
          appType={appStatus?.appType}
          subject = {subject}
          onCallJoinRequest = {this.onCallJoinRequest}
          >
          <NavigationScreens
            ref={(navigatorRef) => {
              NavigationService.setTopLevelNavigator(navigatorRef)
            }}
            screenProps={finalScreenProps}
          />
        </CustomSocket>
      </View>
    )
  }
}

const mapStateToProps = (state) => ({
  appStatus: state.appStatus,
  unreadChats: state.chat?.unreadChats?.count,
  timeZones: state.timeZone?.list,
  storage: state.appStatus.storage,
  subject: getSubject(state),
  userTimezone: getUserTimezone(state),
  user: getUser(state),
  currentScreen: getCurrentScreen(state),
  isAcknowledged: _.get(getUserSession(state), 'isAcknowledged', false),
  isQuestionnaireUpdateRequired: _.get(getUserSession(state), 'isQuestionnaireUpgradeRequired', false),
  isPinValidated: state?.appStatus?.isPinValidated,
  isRefetchingMetaData: getVersionUpgradeLoader(state),
})

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      storeAppStatus,
      getOfflineForms,
      checkForDataSync,
      storeSyncStatus,
      updateSyncCompleteStatus,
      updateUserTimezone,
      updateIsAcknowledged,
      updateStudyMetaData,
      updateDeviceToken
    },
    dispatch
  )


  AppNavigation.defaultProps = {
    userTimezone: null,
    updateSyncCompleteStatus: ()=> null,
    appStatus: {
      isDeviceOnline: true
    },
    storeSyncStatus: ()=> null,
    screenProps: {
      t: () => null,
      appType: coreConstants.AppType.SUBJECT,
      locale: coreConstants.locale.english,
    },
    storeAppStatus: () => null,
    timeZones: [],
    updateUserTimezone: () => null,
    updateIsAcknowledged: () => null,
    subject: {
      id: null,
      lastUpdatedDateOn: null,
    },
    navigation: {},
    storage : {},
    user: {},
    currentScreen: null,
    isAcknowledged: false,
    updateStudyMetaData: () => null,
    isQuestionnaireUpdateRequired: false,
    updateDeviceToken: () => null,
    isPinValidated: Platform.OS === 'web',
  }
  
  AppNavigation.propTypes = {
    userTimezone: PropTypes.string,
    updateSyncCompleteStatus: PropTypes.func,
    appStatus: PropTypes.shape({
      isDeviceOnline: PropTypes.bool,
      appType: PropTypes.string,
    }),
    storeSyncStatus: PropTypes.func,
    screenProps:PropTypes.shape({
      t: PropTypes.func,
      locale: PropTypes.string,
    }),
    storeAppStatus: PropTypes.func,
    timeZones: PropTypes.array,
    updateUserTimezone: PropTypes.func,
    updateIsAcknowledged: PropTypes.func,
    subject: PropTypes.shape({
      id: PropTypes.string,
      lastUpdatedDateOn: PropTypes.string,
    }),
    navigation: PropTypes.instanceOf(Object),
    storage: PropTypes.instanceOf(Object),
    user: PropTypes.instanceOf(Object),
    currentScreen: PropTypes.string,
    isAcknowledged: PropTypes.bool,
    updateStudyMetaData: PropTypes.func,
    isQuestionnaireUpdateRequired: PropTypes.bool,
    updateDeviceToken: PropTypes.func,
    isPinValidated: PropTypes.bool,
    isRefetchingMetaData: PropTypes.bool.isRequired
  } 

  

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation)

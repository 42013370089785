import React from 'react'
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native'
import { Entypo } from '@expo/vector-icons'
import PropTypes from 'prop-types'
import { Colors } from '../common/ColorScheme'

function TimeAdjuster({ label, onIncrement, onDecrement }) {
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onIncrement}>
        <Entypo name="triangle-up" size={24} color={Colors.headingColor} />
      </TouchableOpacity>
      <Text style={styles.label}>{label}</Text>
      <TouchableOpacity onPress={onDecrement}>
        <Entypo name="triangle-down" size={24} color={Colors.headingColor} />
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginHorizontal: 10,
  },
  label: {
    fontSize: 16,
    marginVertical: 5,
  },
})

TimeAdjuster.propTypes = {
  label: PropTypes.string.isRequired,
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
}

export default TimeAdjuster

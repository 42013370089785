import axios from 'axios'
import JSOG from 'jsog'
import properties from '../constants/constants'
import { validateToken } from '../actions/users'
import _ from 'lodash'
import { validateSubjectToken } from '../actions/login'
import showToast from '../utils/toast'
import { getDeviceStatus } from '../actions/users'
import appConstants from '../constants/appConstants'
import { store } from '../store/configStore'
import constants from '../constants/constants'
import storage from './storage/storage'
import { Platform } from 'react-native'
import { decryptString } from './cryptoUtil'
import { getUserId } from '../selectors/user'
import { getAppType, getContextBasedUrl } from './apiConfigs/utils'

let baseUrl = `${appConstants.baseUrl}/api/app`

const axiosConfig = axios.create({
  baseURL: baseUrl,
  headers: { 'Access-Control-Allow-Origin': '*' },
  transformResponse: [].concat(axios.defaults.transformResponse, (data) => JSOG.decode(data)),
})

axiosConfig.interceptors.request.use(
  (config) => getConfig(config),
  (error) => {
    Promise.reject(error)
  }
)
axiosConfig.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error)
    if (axios.isCancel(error)) {
      console.log('Request canceled', error)
      return Promise.resolve(error)
    } else {
      return Promise.reject(error)
    }
  }
)
export const getConfig = async (config) => {
  const contextBasedUrl = getContextBasedUrl(config.url);
  config.url = contextBasedUrl;
  const isDeviceOnline = getDeviceStatus()
  const userId = getUserId(store.getState())
  if (isDeviceOnline) {
    const isLoginRequest = _.includes(config.url, '/login') || _.includes(config.url, '/passcode')
    if(!isLoginRequest){
      const appType = getAppType();
      if (properties.AppType.SITESTAFF === appType) {
          const data = await validateToken()
          if (!_.isEmpty(data)) {
            config.headers['Authorization'] = data.accessToken
            config.headers['Realmname'] = data.keycloakRealm
            config.headers['PrimaryOrganizationcode'] = data.primaryOrganizationCode
          }else {
                config.cancelToken = new axios.CancelToken((cancel) =>
                  cancel('Request cancelled due to session expiry')
                )
          }
      } else {
        const isOtpRequest =
          _.includes(config.url, '/subject/otp') ||
          _.includes(config.url, '/subject/resetPassword')
        if (isOtpRequest) {
          config.headers.common = {}
        } else if(_.includes(config.url, '/generateToken')) {
          config.headers['SubjectAuthorization-Token'] = decryptString(storage.getString(userId+'SubjectRefreshToken'))
        }else {
          const data = await validateSubjectToken()
          if (appType && !_.isEmpty(data)) {
            config.headers['SubjectAuthorization-Token'] = data?.subjectDeviceToken
          } else if(!appType && Platform.OS == 'web' && _.includes(_.lowerCase(window.location.href), 'preview') ){
            config.headers['SubjectAuthorization-Token'] = decryptString(storage.getString(constants.AppType.PREVIEW+'PreviewToken'))
          } else if(!appType){
            config.headers['SubjectAuthorization-Token'] = decryptString(storage.getString(constants.AppType.PRINT+'PrintToken'))
          } else {
                config.cancelToken = new axios.CancelToken((cancel) =>
                  cancel('Request cancelled due to session expiry')
                )
          }
        }
      }
    }
    return config
  } else {
    config.cancelToken = new axios.CancelToken((cancel) => cancel('No Internet connection'))
    showToast("You're offline", 'danger', 5000)
    return config
  }
}

export default axiosConfig

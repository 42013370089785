import axios from 'axios'
import JSOG from 'jsog'
import appConstants from '../../constants/appConstants'

let baseUrl = `${appConstants.baseUrl}/api/app`

const axiosConfig = axios.create({
  baseURL: baseUrl,
  headers: { 'Access-Control-Allow-Origin': '*' },
  transformResponse: [].concat(axios.defaults.transformResponse, (data) => JSOG.decode(data)),
})



export default axiosConfig

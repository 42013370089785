import { encode as btoa } from 'base-64'
import 'text-encoding-polyfill'
import {Dimensions, Platform} from 'react-native'
import constants from '../constants/constants'
import momentTz from 'moment-timezone'
import _ from 'lodash'
import moment from 'moment'

const {width: SCREEN_WIDTH} = Dimensions.get('window')
function bytesToBase64(bytes) {
  const binString = Array.from(bytes, (x) => String.fromCodePoint(x)).join('')
  return btoa(binString)
}

export const generateBasicAuthToken = (userName, password) =>
  `Basic ${bytesToBase64(new TextEncoder().encode(`${userName}:${password}`))}`

export const generateBasicAuthHeader = (userName, password) =>
  `Authorization: ${generateBasicAuthToken(userName, password)}`

export const checkForOfflineFailureStatus = (response) => {
  if (response) {
    return (
      response.includes('Failed to connect') ||
      response.includes('Software caused connection abort')
    )
  }
}

export const generatePercentage = (value, total) => {
  const percentageValue = (value / total) * 100
  return percentageValue
}

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

export const imageExists = (url) => {
  return new Promise(resolve => {
    let img = new Image()
    img.addEventListener('load', () => resolve(true))
    img.addEventListener('error', () => resolve(false))
    img.src = url
  })
}

export const removePercentageFromValue = (value) => {
  return (value != null && value != "" && value != undefined) ? value?.toString().replace(/[%\s]/g, '') : null
}

export const getScreenWidth = () => {
  if(Platform.OS === 'web'){
    return SCREEN_WIDTH >= 1030 ? 800 : SCREEN_WIDTH
  }else{
    return SCREEN_WIDTH
  }
}

export const getLoggedInUserIdFromSession = (currentUserId) => {
  if(Platform.OS === 'web'){
    return sessionStorage.getItem('tabSessionUserId')
  }else{
    return currentUserId;
  }
}

export const storeUserIdInSessionStorage = (userId) => {
  if(Platform.OS === 'web'){
    sessionStorage.setItem('tabSessionUserId', userId);
  }
}

export const removeUserIdFromSessionStorage = () => {
  if(Platform.OS === 'web'){
    sessionStorage.removeItem('tabSessionUserId');
  }
}

export const storeSiteIdInSessionStorage = (siteId) => {
  if(Platform.OS === 'web'){
    sessionStorage.setItem('tabSessionSiteId', siteId);
  }
}

export const getSiteIdFromSession = (siteId) => {
  if(Platform.OS === 'web'){
    return sessionStorage.getItem('tabSessionSiteId')
  }else{
    return siteId;
  }
}

export const removeSiteIdFromSessionStorage = () => {
  if(Platform.OS === 'web'){
    sessionStorage.removeItem('tabSessionSiteId');
  }
}

export const storeModeInSessionStorage = (mode) => {
  if(Platform.OS === 'web'){
    sessionStorage.setItem('tabSessionMode', mode);
  }
}


export const getModeFromSessionStorage = (mode) => {
  if(Platform.OS === 'web'){
    return sessionStorage.getItem('tabSessionMode');
  }else{
    return constants.AppType.SUBJECT
  }
}

export const getTimezoneCode = (timezone) => {
  if(!_.isEmpty(timezone)){
    return momentTz().tz(timezone).format('z')
  } 
    return  momentTz().tz(moment.tz.guess(true)).format('z')
}